<template>
   <div></div>
</template>

<script>

import User from "@/services/user";
import stores from "@/vuex";

export default {
   name: 'SecurityLogin',
   data() {
      return {}
   },

   mounted() {
      this.loginUser();
   },

   methods: {
      async loginUser() {
         let token = this.$route.params.token;
         await this.$store.dispatch('ActionSingOut');
         User.security(token).then((resp) => {
            stores.dispatch('ActionDoLogin', resp.data);
            this.$router.push({name: 'Home'});
         }).catch((err) => {
            console.log(err)
         })
      }
   }


}
</script>

<style>

</style>
